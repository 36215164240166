
import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { notification, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loadCurrentAccount, authentication, setProfileComplate } from "../../redux/actions/userActions";
import { Spinner } from "react-bootstrap";
import { UPDATEDPOST } from "services/common.api";
import store from 'store'
import moment from "moment";

const CompleteNewPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cognitoUserData = useSelector((state:any) => state.userReducer.cognitoUserData)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSumbitForm = (event) => {
    event.preventDefault();
    validatePassword(confirmPassword)
    completeNewPasswordFlow(password);
    // signIn("wrx.patient@yopmail.com", "Welcome@123");
  };

  const validatePassword = (passwordValue) => {
      if(password !== passwordValue){
        setPasswordError("The two passwords that you entered do not match!")
      } else {
        setPasswordError("")
      }
  }

  async function completeNewPasswordFlow(newPassword) {
    setLoader(true);
    console.log("password: ", password);

    var email = store.get('email');
    var tempPassword = store.get('tempPassword');
    console.log(email,"emailll")
    console.log(tempPassword,"password")
    console.log(newPassword," new password")

    try {

      let data = {
        email : email,
        password : tempPassword,
        newPassword : newPassword
      }
      const cognitoUser = await UPDATEDPOST(`user/login/reset`, data);
      console.log(cognitoUser,"ccc")
        // const cognitoUser = await Auth.completeNewPassword(cognitoUserData, newPassword)
        if(cognitoUser?.data?.result){
          store.set('auth_token_refresh', cognitoUser?.data?.result?.idToken?.jwtToken);
          store.set('auth_token_exp', cognitoUser?.data?.result?.idToken?.payload?.exp);
          store.set('sub',cognitoUser?.data?.result?.idToken?.payload?.sub)
          store.set("accessToken",cognitoUser?.data?.result?.accessToken?.jwtToken)
          dispatch(loadCurrentAccount());
          dispatch(authentication(true));
          setLoader(false);

          const currentTime = new Date();
          const formattedTime = currentTime.toISOString();
          localStorage.setItem(
            "current_time",
            moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
          );

          dispatch(setProfileComplate("false"));
          history.push("/dashboard");
        }else{
          setLoader(false);
        }
        
    } catch (error) {
      console.log('error: ', error);
      setLoader(false);
      notification.error({
        message: 'Error',
        duration: 2,
        description:
          error.message,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
  }

  return (
    <form onSubmit={handleSumbitForm}>
      <div className={style.div_container}>
        <div className={style.div_content}>
          <div className={style.div_image}>
            <div>
              {/* <p className="p_name">Welcome</p> */}
              <p className={style.p_name}>Please set your password to continue</p>
            </div>
            <div className={style.div_round}>
              <img
                src="Images/logo.png"
                className={style.imgFluid}
                alt="Doccure Login"
              />
            </div>
          </div>

          <div className={style.div_form}>
            <div className="form-group form-focus">
              <input type="password" className="form-control floating" value={password} onChange={(text: any) => setPassword(text.target.value)} />
              <label className="focus-label">Password</label>
            </div>
            <div className="form-group form-focus form-group-no-mb">
              <input type="password" className="form-control floating" value={confirmPassword} onChange={(text: any) => { setConfirmPassword(text.target.value)
                validatePassword(text.target.value)
            }} />
              <label className="focus-label">Confirm Password</label>
            </div>
            <div className={style.form_group_error}><span className={style.field_error}>{passwordError}</span></div>
            {!loader ?
              <button
                className={`btn btn-primary btn-block`}
                type="submit"
              //onClick={continuee}
              >
                Submit
              </button> :
              <button
                className="btn btn-primary btn-block"
                type="submit"
              //onClick={continuee}
              >
                {/* <Spin style={{ color: "white" }}></Spin> */}
                <Spinner animation="border" role="status" style={{ color: "white", width: 25, height: 25 }} />
              </button>}
          </div>
        </div>
        <div className={`text-center  ${style.copy}`}>© 2021 ONRx</div>
      </div>
    </form>
  );
};

export default CompleteNewPassword;
