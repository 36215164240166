import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { IMG03, IMG04, IMG05, IMG06, IMG07, IMG08, IMG11 } from "./img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrown,
  faDiceTwo,
  faDotCircle,
  faGlobe,
  faLongArrowAltRight,
  faMapMarker,
  faMapPin,
  faPhone,
  faPhoneAlt,
  faReply,
  faStar,
  faThumbsUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss";
import { UPDATEDGET } from "../../services/common.api";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import { Rate } from "antd";
import moment from "moment";

const DocProfile = () => {
  const [doctorProfileData, setDoctorProfileData] = useState({} as any);
  const [docId, setDocID] = useState(undefined);
  const [docAvailability, setDocAvailability] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openOrNot, setOpenOrNot] = useState(false);
  const userProfile = useSelector(
    (state: any) => state.selectedDocterProfile.doc_PRO
  );
  const selectedDoctorID = useSelector(
    (state: any) => state.selectedDocterProfile.doc_PRO.id
  );
  // const [todayAvailability, setTodayAvailabity] = useState(
  //   "09:00 AM - 05:00 PM"
  // );
  const [todayAvailability, setTodayAvailabity] = useState(
    "-"
  );
  // const selectedDoctorID = localStorage.getItem("DOC_ID");
  const [availibilityInitial, setAvailibilityInitial] = useState([]);
  const [timeOptions, setTimeOptions] = useState([
    // AM times
    { name: "12:00 AM", value: "0" },
    { name: "12:30 AM", value: "30" },
    { name: "01:00 AM", value: "100" },
    { name: "01:30 AM", value: "130" },
    { name: "02:00 AM", value: "200" },
    { name: "02:30 AM", value: "230" },
    { name: "03:00 AM", value: "300" },
    { name: "03:30 AM", value: "330" },
    { name: "04:00 AM", value: "400" },
    { name: "04:30 AM", value: "430" },
    { name: "05:00 AM", value: "500" },
    { name: "05:30 AM", value: "530" },
    { name: "06:00 AM", value: "600" },
    { name: "06:30 AM", value: "630" },
    { name: "07:00 AM", value: "700" },
    { name: "07:30 AM", value: "730" },
    { name: "08:00 AM", value: "800" },
    { name: "08:30 AM", value: "830" },
    { name: "09:00 AM", value: "900" },
    { name: "09:30 AM", value: "930" },
    { name: "10:00 AM", value: "1000" },
    { name: "10:30 AM", value: "1030" },
    { name: "11:00 AM", value: "1100" },
    { name: "11:30 AM", value: "1130" },
    // PM times
    { name: "12:00 PM", value: "1200" },
    { name: "12:30 PM", value: "1230" },
    { name: "01:00 PM", value: "1300" },
    { name: "01:30 PM", value: "1330" },
    { name: "02:00 PM", value: "1400" },
    { name: "02:30 PM", value: "1430" },
    { name: "03:00 PM", value: "1500" },
    { name: "03:30 PM", value: "1530" },
    { name: "04:00 PM", value: "1600" },
    { name: "04:30 PM", value: "1630" },
    { name: "05:00 PM", value: "1700" },
    { name: "05:30 PM", value: "1730" },
    { name: "06:00 PM", value: "1800" },
    { name: "06:30 PM", value: "1830" },
    { name: "07:00 PM", value: "1900" },
    { name: "07:30 PM", value: "1930" },
    { name: "08:00 PM", value: "2000" },
    { name: "08:30 PM", value: "2030" },
    { name: "09:00 PM", value: "2100" },
    { name: "09:30 PM", value: "2130" },
    { name: "10:00 PM", value: "2200" },
    { name: "10:30 PM", value: "2230" },
    { name: "11:00 PM", value: "2300" },
    { name: "11:30 PM", value: "2330" },
    { name: "11:59 PM", value: "2359" },
  ]);

  useEffect(() => {
    console.log(selectedDoctorID, "userProfile *********");
    setDocID(selectedDoctorID);
    // getAppointments();
  }, [userProfile]);

  const getUserImage = (imageUrl) => {
    if (
      imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !=
      null
    ) {
      return imageUrl;
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
  };

  const getSpeciality = (speciality) => {
    if (speciality) {
      return speciality.split(",");
    } else {
      return [];
    }
  };

  async function getAllAvailibility(doctorId) {
    try {
      const TodayDate = moment(new Date()).format("dddd");

      const getAllAvailibilities = await UPDATEDGET(
        `availabilities?docId=${doctorId}`
      );

      const tempArray = [
        {
          day: "Monday",
          timings: [] as any,
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Tuesday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Wednesday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Thursday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Friday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Saturday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
        {
          day: "Sunday",
          timings: [],
          urgentTiming: { openingTime: "", closingTime: "" },
        },
      ];
      //  console.log("tempArray::::::", tempArray)

      getAllAvailibilities?.data?.body?.forEach((item) => {
        _.find(tempArray, (o) =>
          o.day === item.day
            ? o.timings.push({
              closingTime: `${item.endtime}`,
              openingTime: `${item.starttime}`,
              id: item.id,
            })
            : null
        );
      });

      console.log(tempArray, "tempArray tempArray")
      let todayAvailability = "";
      let isOpen = false;

      tempArray.length &&
        tempArray.map((data, i) => {

          data.timings.map((ar) => {
            if (
              ar?.openingTime !== "" ||
              ar?.closingTime !== ""
            ) {
              timeOptions.map((item) => {
                if (item.value === ar?.openingTime) {
                  ar.openingTime = item.name;
                }
                if (item.value === ar?.closingTime) {
                  ar.closingTime = item.name;
                }
              });
            }

            if (data.day === TodayDate) {
              const openingTime = moment(ar?.openingTime, "hh:mm A");
              const closingTime = moment(ar?.closingTime, "hh:mm A");
      
              if (moment().isBetween(openingTime, closingTime)) {
                setOpenOrNot(true)
              }
      
              todayAvailability += `${ar?.openingTime}-${ar?.closingTime} , `;
              setTodayAvailabity(todayAvailability);
            }

          } )

          // if (data.day === TodayDate) {
          //   const val =
          //     data?.timings[0]?.openingTime +
          //     "-" +
          //     data?.timings[0]?.closingTime;
          //   if (
          //     data?.timings[0]?.openingTime !== undefined &&
          //     data?.timings[0]?.closingTime !== undefined
          //   ) {
          //     setTodayAvailabity(val);

          //     const currentTime = moment().format("HH:mm A");
          //     const cTime = moment(currentTime, "HH:mm A");

          //     if (cTime.isBetween(moment(data?.timings[0]?.openingTime, 'HH:mm A'), moment(data?.timings[0]?.closingTime, 'HH:mm A'))) {
          //       setOpenOrNot(true);
          //     } else {
          //       setOpenOrNot(false);
          //     }

          //   } else {
          //     setTodayAvailabity("-");
          //     setOpenOrNot(false);
          //   }
          // }
        });

      setAvailibilityInitial(tempArray);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const currentTime = moment().format("hh:mm a");
    const cTime = moment(currentTime, "hh:mm a");

    const beforeTime = moment("09:00 am", "hh:mm a");
    const afterTime = moment("05:00 pm", "hh:mm a");
    console.log("current Time", cTime);

    getDoctorList();
    // if (cTime.isBetween(beforeTime, afterTime)) {
    //   setOpenOrNot(true);
    // } else {
    //   setOpenOrNot(false);
    // }
    if (docId) {
      getAppointments();
      getAvailability();
    }
  }, [docId]);

  const getDoctorList = async () => {
    const doctorList = await UPDATEDGET(`employee/doctors`);
    console.log(doctorList, "-*-*-*All doctorList");
    doctorList?.data.map((item) => {
      if (docId === item.id) {
        getAllAvailibility(item.companyemployeeid);
      }
    });
  };

  const getAppointments = async () => {
    console.log("ok");
    try {
      if (docId) {
        console.log(docId, "docId");
        setLoader(true);
        const docProfile = await UPDATEDGET(`doctor/profile/${userProfile.id}`);
        setDoctorProfileData(docProfile.data[0]);
        console.log("TE");
        console.log(docProfile);
        console.log("TE");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  function getTime(time) {
    let timefinal = (Math.round((time / 100) * 100) / 100).toFixed(2);
    return moment(timefinal, ["HH.mm"]).format("hh:mm a");
    // return timefinal;
  }

  const getAvailability = async () => {
    try {
      if (docId) {
        setLoader(true);
        const availability = await UPDATEDGET(
          `doctoravailability?date=2021-09-01&docid=${docId}`
        );

        console.log("This is Availability");
        setDocAvailability(availability.data[0]);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <>
      {/* <div className="content" style={{ backgroundColor: '#e1e1e1' }}> */}
      <div className="container">
        {loader ? (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {/* <div className="card"> */}
            {/* <div className="card-body"> */}
            <div className="doctor-widget">
              <div className="doc-info-left">
                <div className="doctor-img" style={{ borderRadius: 100 }}>
                  {doctorProfileData?.profilepicture != null && doctorProfileData?.profilepicture != '' ? (
                    <img
                      // src={doctorProfileData?.profilepicture}
                      src={getUserImage(doctorProfileData?.profilepicture)}
                      className={`img-fluid ${style.userProfile}`}
                      alt="User"
                    />
                  ) : (
                    <img
                      // src="https://doccure.dreamguystech.com/template/assets/img/doctors/doctor-thumb-02.jpg"
                      src={IMG11}
                      className={`img-fluid ${style.userProfile}`}
                      alt="User"
                    />
                  )}
                </div>
                <div className="doc-info-cont">
                  <h4 className="doc-name">
                    Dr. {doctorProfileData?.firstname}{" "}
                    {doctorProfileData?.middlename}{" "}
                    {doctorProfileData?.lastname}
                  </h4>
                  {/* <p className="doc-speciality">
                                                    {doctorProfileData.speciality}
                                                </p> */}
                  <p className="doc-department">
                    {/* <img src={IMG02} className="img-fluid" alt="Speciality" /> */}
                    {/* <FontAwesomeIcon className="mr-2" icon={faTooth} /> */}
                    {doctorProfileData.Profession}
                  </p>
                  <div className="rating">
                    <Rate disabled />
                    {/* <span className="d-inline-block average-rating pt-2 pl-2">(35)</span> */}
                  </div>
                  <div className="clinic-details">
                    {/* <p className="doc-location mb-2"><FontAwesomeIcon icon={faMapPin} className="mr-2" /> {doctorProfileData.state}- <a href="#0">Get Directions</a></p> */}
                    <p className="mb-0">
                      <FontAwesomeIcon icon={faMapMarker} className="mr-2" />{" "}
                      {doctorProfileData?.address1} {doctorProfileData?.city}{" "}
                      {doctorProfileData?.country}
                    </p>
                    <p className="mb-0 mt-2">
                      <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />{" "}
                      {doctorProfileData?.phoneNumber || doctorProfileData?.phone}
                    </p>
                    <p className="mb-0 mt-2">
                      <FontAwesomeIcon icon={faUser} className="mr-2" />{" "}
                      {/* {doctorProfileData?.gender} */}
                      {doctorProfileData?.gender != null ? doctorProfileData?.gender.charAt(0).toUpperCase() + doctorProfileData?.gender.slice(1) : ''}
                    </p>

                    {/* <ul className="clinic-gallery">
                                                    <li>
                                                        <a href="#0">
                                                            <img src={IMG03} alt="Feature" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#0" >
                                                            <img src={IMG04} alt="Feature" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#0" >
                                                            <img src={IMG05} alt="Feature" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#0">
                                                            <img src={IMG06} alt="Feature" />
                                                        </a>
                                                    </li>
                                                </ul> */}
                  </div>
                  {/* Speciality Hided Here */}
                  {/* <div className="clinic-services">
                                                    {getSpeciality(doctorProfileData?.speciality)?.map((itemm, index) => {
                                                        return (
                                                            <>
                                                            {itemm != '' ?
                                                                <span key={window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16)}>{itemm}</span>
                                                            :null }
                                                            </>
                                                        )}
                                                    )}
                                                </div> */}
                </div>
              </div>
              <div className={`doc-info-right ${style.docRight}`}>
                {/* <div className={`doctor-action  ${style.leftBtn}`}>
                                                <a href="#0" className={`${style.customBtnBorder}`}>
                                                    <FontAwesomeIcon icon={faBookmark} />
                                                </a>
                                                <Link to="/doctor/chat-doctor" className={`${style.customBtnBorder}`}>
                                                    <FontAwesomeIcon icon={faComment} />
                                                </Link>
                                                <a href="#0"
                                                    className={`${style.customBtnBorder}`}
                                                    data-toggle="modal"
                                                >
                                                    <FontAwesomeIcon icon={faPhone} />
                                                </a>
                                                <a href="#0"
                                                    className={`${style.customBtnBorder}`}
                                                    data-toggle="modal"
                                                >
                                                    <FontAwesomeIcon icon={faVideo} />
                                                </a>
                                            </div> */}
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            {/* <div className="card">
                                <div className="card-body pt-0"> */}
            <Tabs
              className="tab-view"
              // activeKey={1}
              // onSelect={this.handleSelect}
              id="controlled-tab-example"
            >
              <Tab className="nav-item pb-2" eventKey={1} title="Overview">
                <div className="row">
                  <div className="col-md-12 col-lg-12 pt-4">
                    {/* <div className="widget about-widget">
                                            <div className={`${style.div_title_decorate}`}>
                                                <FontAwesomeIcon icon={faUser} className="mr-3" />
                                                <h4 className="m-0">About Me</h4>
                                            </div>
                                            <p>{doctorProfileData.addlinformation ? doctorProfileData.addlinformation : " No Data Found"}</p>
                                        </div> */}

                    {/* <div className="widget education-widget">
                                                        <h4 className="widget-title">Education</h4>
                                                        <div className="experience-box">
                                                            <ul className="experience-list">
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <a href="#/" className="name">American Dental Medical University</a>
                                                                            <div>BDS</div>
                                                                            <span className="time">1998 - 2003</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <a href="#/" className="name">American Dental Medical University</a>
                                                                            <div>MDS</div>
                                                                            <span className="time">2003 - 2005</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="widget experience-widget">
                                                        <h4 className="widget-title">Work & Experience</h4>
                                                        <div className="experience-box">
                                                            <ul className="experience-list">
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <a href="#/" className="name">Glowing Smiles Family Dental Clinic</a>
                                                                            <span className="time">2010 - Present (5 years)</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <a href="#/" className="name">Comfort Care Dental Clinic</a>
                                                                            <span className="time">2007 - 2010 (3 years)</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <a href="#/" className="name">Dream Smile Dental Practice</a>
                                                                            <span className="time">2005 - 2007 (2 years)</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="widget awards-widget">
                                                        <h4 className="widget-title">Awards</h4>
                                                        <div className="experience-box">
                                                            <ul className="experience-list">
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <p className="exp-year">July 2019</p>
                                                                            <h4 className="exp-title">Humanitarian Award</h4>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <p className="exp-year">March 2011</p>
                                                                            <h4 className="exp-title">Certificate For International Volunteer Service</h4>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="experience-user">
                                                                        <div className="before-circle"></div>
                                                                    </div>
                                                                    <div className="experience-content">
                                                                        <div className="timeline-content">
                                                                            <p className="exp-year">May 2008</p>
                                                                            <h4 className="exp-title">The Dental Professional of The Year Award</h4>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                    {/* <div className="service-list">
                                                        <h4>Services</h4>
                                                        <ul className="clearfix">
                                                            <li>Tooth cleaning </li>
                                                            <li>Root Canal Therapy</li>
                                                            <li>Implants</li>
                                                            <li>Composite Bonding</li>
                                                            <li>Fissure Sealants</li>
                                                            <li>Surgical Extractions</li>
                                                        </ul>
                                                    </div> */}

                    <div className="service-list">
                      <div className={`${style.div_title_decorate}`}>
                        <FontAwesomeIcon icon={faGlobe} className="mr-3" />
                        <h4 className="m-0">Languages spoken</h4>
                      </div>
                      <div className={`${style.div_language}`}>
                        {getSpeciality(doctorProfileData?.languages)?.map(
                          (itemm, index) => {
                            return (
                              <>
                                {itemm != "" ? (
                                  <>
                                    <div
                                      className={`${style.div_inner_language}`}
                                      key={window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faLongArrowAltRight}
                                        className={`${style.iconArrowProfile} mr-2`}
                                      />
                                      {itemm}
                                    </div>
                                  </>
                                ) : null}
                              </>
                            );
                          }
                        )}
                      </div>
                      {/* <p><FontAwesomeIcon icon={faLongArrowAltRight} className={`${style.iconArrowProfile} mr-2`} /> {doctorProfileData.languages ? doctorProfileData.languages : " No Data Found"}</p> */}
                    </div>

                    <div className="service-list">
                      <div className={`${style.div_title_decorate}`}>
                        <FontAwesomeIcon icon={faCrown} className="mr-3" />
                        <h4 className="m-0">Specializations</h4>
                      </div>
                      <div className={`${style.div_language}`}>
                        {getSpeciality(doctorProfileData?.speciality)?.map(
                          (itemm, index) => {
                            return (
                              <>
                                {itemm != "" ? (
                                  <div
                                    className={`${style.div_inner_language}`}
                                    key={window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faLongArrowAltRight}
                                      className={`${style.iconArrowProfile} mr-2`}
                                    />
                                    {itemm}
                                  </div>
                                ) : null}
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {/* <Tab className="nav-item" eventKey={2} title="Locations">
                                <div>
                                    <div className="location-list">
                                        <div className="row">


                                            <div className="col-md-6">
                                                <div className="clinic-content">
                                                    <h4 className="clinic-name"><a href="#0">Smile Cute Dental Care Center</a></h4>
                                                    <p className="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                    <div className="rating">
                                                        <Rate disabled />
                                                        <span className="d-inline-block average-rating pt-2 pl-2">(4)</span>
                                                    </div>
                                                    <div className="clinic-details mb-0">
                                                        <h5 className="clinic-direction">
                                                            <FontAwesomeIcon icon={faMapMarker} /> 2286  Sundown Lane, Austin, Texas 78749,
                                                            USA <a href="#0">Get Directions</a></h5>
                                                        <div>
                                                        </div>
                                                        <ul className="clinic-gallery">
                                                            <li>
                                                                <a href="#0">
                                                                    <img src={IMG03} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0">
                                                                    <img src={IMG04} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0">
                                                                    <img src={IMG05} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0">
                                                                    <img src={IMG06} alt="Feature" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="clinic-timing">
                                                    <div>
                                                        <p className="timings-days">
                                                            <span> Mon - Sat </span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>10:00 AM - 2:00 PM</span>
                                                            <span>4:00 PM - 9:00 PM</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="timings-days">
                                                            <span>Sun</span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>10:00 AM - 2:00 PM</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="consult-price">
                                                    $250
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="location-list">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="clinic-content">
                                                    <h4 className="clinic-name"><a href="#0">The Family Dentistry Clinic</a></h4>
                                                    <p className="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                    <div className="rating">
                                                        <Rate disabled />
                                                        <span className="d-inline-block average-rating px-2">(4)</span>
                                                    </div>
                                                    <div className="clinic-details mb-0">
                                                        <p className="clinic-direction"> <FontAwesomeIcon icon={faMapMarker} />
                                                            2883  University Street, Seattle, Texas Washington, 98155<a href="#0">Get Directions</a></p>
                                                        <div>
                                                        </div>
                                                        <ul className="clinic-gallery">
                                                            <li>
                                                                <a href="#0" >
                                                                    <img src={IMG03} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0" >
                                                                    <img src={IMG04} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0" >
                                                                    <img src={IMG05} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#0" >
                                                                    <img src={IMG06} alt="Feature" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="clinic-timing">
                                                    <div>
                                                        <p className="timings-days">
                                                            <span> Tue - Fri </span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>11:00 AM - 1:00 PM</span>
                                                            <span>6:00 PM - 11:00 PM</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="timings-days">
                                                            <span>Sat - Sun</span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>8:00 AM - 10:00 AM</span>
                                                            <span>3:00 PM - 7:00 PM</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-2">
                                                <div className="consult-price">
                                                    $350
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </Tab> */}
              {/* <Tab className="nav-item" eventKey={3} title="Reviews">
                                            <div>
                                                <div className="widget review-listing">
                                                    <ul className="comments-list">

                                                        <li>
                                                            <div className="comment">
                                                                <img className="avatar avatar-sm rounded-circle" alt="User" src={IMG07} />
                                                                <div className="comment-body">
                                                                    <div className="meta-data">
                                                                        <span className="comment-author">Richard Wilson</span>
                                                                        <span className="comment-date">Reviewed 2 Days ago</span>
                                                                        <div className="review-count rating">
                                                                            <Rate />
                                                                        </div>
                                                                    </div>
                                                                    <p className="recommended"> <FontAwesomeIcon icon={faThumbsUp} /> I recommend the doctor</p>
                                                                    <p className="comment-content">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                        Ut enim ad minim veniam, quis nostrud exercitation.
                                                                        Curabitur non nulla sit amet nisl tempus
                                                                    </p>
                                                                    <div className="comment-reply">
                                                                        <a className="comment-btn" href="#0">
                                                                            <FontAwesomeIcon icon={faReply} /> Reply
                                                                        </a>
                                                                        <p className="recommend-btn">
                                                                            <span>Recommend?</span>
                                                                            <a href="#0" className="like-btn">
                                                                                <FontAwesomeIcon icon={faThumbsUp} /> Yes
                                                                            </a>
                                                                            <a href="#0" className="dislike-btn">
                                                                                <FontAwesomeIcon icon={faThumbsUp} /> No
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <ul className="comments-reply">
                                                                <li>
                                                                    <div className="comment">
                                                                        <img className="avatar avatar-sm rounded-circle" alt="User" src={IMG08} />
                                                                        <div className="comment-body">
                                                                            <div className="meta-data">
                                                                                <span className="comment-author">Charlene Reed</span>
                                                                                <span className="comment-date">Reviewed 3 Days ago</span>
                                                                                <div className="review-count rating">
                                                                                    <Rate />
                                                                                </div>
                                                                            </div>
                                                                            <p className="comment-content">
                                                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                                Ut enim ad minim veniam.
                                                                                Curabitur non nulla sit amet nisl tempus
                                                                            </p>
                                                                            <div className="comment-reply">
                                                                                <a className="comment-btn" href="#0">
                                                                                    <FontAwesomeIcon icon={faReply} /> Reply
                                                                                </a>
                                                                                <p className="recommend-btn">
                                                                                    <span>Recommend?</span>
                                                                                    <a href="#0" className="like-btn">
                                                                                        <FontAwesomeIcon icon={faThumbsUp} />Yes
                                                                                    </a>
                                                                                    <a href="#0" className="dislike-btn">
                                                                                        <FontAwesomeIcon icon={faThumbsUp} /> No
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>


                                                        </li>

                                                        <li>
                                                            <div className="comment">
                                                                <img className="avatar avatar-sm rounded-circle" alt="User" src={IMG07} />
                                                                <div className="comment-body">
                                                                    <div className="meta-data">
                                                                        <span className="comment-author">Travis Trimble</span>
                                                                        <span className="comment-date">Reviewed 4 Days ago</span>
                                                                        <div className="review-count rating">
                                                                            <Rate />
                                                                        </div>
                                                                    </div>
                                                                    <p className="comment-content">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                        Ut enim ad minim veniam, quis nostrud exercitation.
                                                                        Curabitur non nulla sit amet nisl tempus
                                                                    </p>
                                                                    <div className="comment-reply">
                                                                        <a className="comment-btn" href="#0">
                                                                            <FontAwesomeIcon icon={faReply} /> Reply
                                                                        </a>
                                                                        <p className="recommend-btn">
                                                                            <span>Recommend?</span>
                                                                            <a href="#0" className="like-btn">
                                                                                <FontAwesomeIcon icon={faThumbsUp} /> Yes
                                                                            </a>
                                                                            <a href="#0" className="dislike-btn">
                                                                                <FontAwesomeIcon icon={faThumbsUp} /> No
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>


                                                    </ul>
                                                </div>

                                                <div className="write-review">
                                                    <h4>Write a review <strong>Dr. Darren Elder</strong></h4>

                                                    <form>
                                                        <div className="form-group">
                                                            <label>Review</label>
                                                            <div className="star-rating">
                                                                <input id="star-5" type="radio" name="rating" value="star-5" />
                                                                <label htmlFor="star-5" title="5 stars">
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </label>
                                                                <input id="star-4" type="radio" name="rating" value="star-4" />
                                                                <label htmlFor="star-4" title="4 stars">
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </label>
                                                                <input id="star-3" type="radio" name="rating" value="star-3" />
                                                                <label htmlFor="star-3" title="3 stars">
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </label>
                                                                <input id="star-2" type="radio" name="rating" value="star-2" />
                                                                <label htmlFor="star-2" title="2 stars">
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </label>
                                                                <input id="star-1" type="radio" name="rating" value="star-1" />
                                                                <label htmlFor="star-1" title="1 star">
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Title of your review</label>
                                                            <input className="form-control" type="text"
                                                                placeholder="If you could say it in one sentence, what would you say?" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Your review</label>
                                                            <textarea id="review_desc" className="form-control"></textarea>

                                                            <div className="d-flex justify-content-between mt-3">
                                                                <small className="text-muted"><span id="chars">100</span> characters remaining</small></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="terms-accept">
                                                                <div className="custom-checkbox">
                                                                    <input type="checkbox" id="terms_accept" />
                                                                    <label htmlFor="terms_accept">I have read and accept
                                                                        <a href="#0">Terms &amp; Conditions</a></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="submit-section">
                                                            <button type="submit" className="btn btn-primary submit-btn">Add Review</button>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </Tab> */}
              <Tab className="nav-item" eventKey={4} title="Business Hours">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="widget business-widget">
                      <div className="widget-content">
                        <div className="listing-hours">
                          <div className="listing-day current">
                            <div className="day">Today</div>
                            <div className="time-items">
                              {openOrNot ? (
                                <span className="open-status">
                                  <span className="badge bg-success-light">
                                    Open Now
                                  </span>
                                </span>
                              ) : (
                                <span className="open-status">
                                  <span className="badge bg-danger-light">
                                    Closed
                                  </span>
                                </span>
                              )}
                              <span className="time">{todayAvailability}</span>
                            </div>
                          </div>
                          {/* {availibilityInitial.length ? (
                            availibilityInitial.map((data, index) => (
                              <div className={style.previous_notes}>
                                <div className="listing-day">
                                  <div className="day">{data?.day}</div>

                                  <div></div>
                                  <div className="time-items">
                                    {data?.timings[0]?.openingTime !==
                                      undefined &&
                                    data?.timings[0]?.closingTime !==
                                      undefined ? (
                                      <span className="time">
                                        {data?.timings[0]?.openingTime} -{" "}
                                        {data?.timings[0]?.closingTime}
                                      </span>
                                    ) : (
                                      // "09:00 AM - 05:00 PM"
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className={style.icon_div}>
                              <p>Not found any doctor notes</p>
                            </div>
                          )} */}

                          {availibilityInitial.length ? (
                            availibilityInitial.map((data, index) => (
                              <div className={style.previous_notes}>
                                <div className="listing-day">
                                  <div className="day">{data?.day}</div>
                                  <div></div>
                                  <div className="time-items">
                                    {data?.timings.length > 0 ? (
                                      data.timings.map((timing, index) => (
                                        <span className="time" key={index}>
                                          {timing.openingTime} - {timing.closingTime}
                                        </span>
                                      ))
                                    ) : (
                                      <span className="time">-</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className={style.icon_div}>
                              <p>Not found any doctor notes</p>
                            </div>
                          )}

                          {/* <div className="listing-day">
                                                                    <div className="day">Monday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day">
                                                                    <div className="day">Tuesday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day">
                                                                    <div className="day">Wednesday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day">
                                                                    <div className="day">Thursday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day">
                                                                    <div className="day">Friday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day">
                                                                    <div className="day">Saturday</div>
                                                                    <div className="time-items">
                                                                        <span className="time">07:00 AM - 09:00 PM</span>
                                                                    </div>
                                                                </div>
                                                                <div className="listing-day closed">
                                                                    <div className="day">Sunday</div>
                                                                    <div className="time-items">
                                                                        <span className="time"><span className="badge bg-danger-light">Closed</span></span>
                                                                    </div>
                                                                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>

            {/* </div>
                            </div> */}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default DocProfile;

function async() {
  throw new Error("Function not implemented.");
}
